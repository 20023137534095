.navBarBg{
    background-color:var(--darkModeColor, dark);
    
}

.navBarLinksColor{
    color: #f2f2f2!important;
}

.navBarLinksColor:hover, .navBarLinksColor.active{
    color:var(--yellowColor)!important;
}
.navBarLinksActive {
    color: var(--yellowColor)!important;
}
.dropDownLinks {
    color: var(--mainLight);
    background: var(--darkModeColor);
}
.dropDownLinks:active{
    color: var(--orangeEbay);
    background: var(--darkModeColor);
}
.dropDownLinksActive {
    color: var(--yellowColor);
    background: var(--darkModeColor);
}
.navBarBrandBottom{
    color:var(--maroonColor);
    text-shadow: .1px .2px var(--yellowColor);
    font-size: 2rem;
    /*float:left;*/
    padding: 0;
    margin:0;
}

.loadingSpinnerColor{
    color:var(--blueViolet);
}

.btnMode{
    border:none;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    background: transparent;
    padding: 0.7rem;
}
.ebayBtn{
    background-color: var(--mainLight);
    padding: .2rem .8rem;
    border-radius: 20px;
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    border: none;
    font-weight: 600;
    /* box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.4);
    -webkit-box-shadow:1px 1px 1px 1px rgba(0,0,0,0.4);
    -moz-box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.4); */
}
.ebayBtn:hover{
    background-color: #ffffb3;
    box-shadow: 2px 1px 4px var(--yellowColor);
    -webkit-box-shadow:2px 1px 4px var(--yellowColor);
    -moz-box-shadow:2px 1px 4px var(--yellowColor);
    transition: all .5s;
    --webkit-transition: all .5s;
}
.ebayE{
    color: var(--redEbay);
}
.ebayB{
    color: var(--blueEbay);
}
.ebayA{
    color: var(--orangeEbay);
}
.ebayY{
    color: var(--greenEbay);
}

.pageFooter{
    background-color: var(--darkModeColor);
    padding: 2rem 1rem;
    color: var(--mainLight);

}
.hrTopFooter{
    margin:0;
    padding: 0;
    height: 2px;
    border-top: 5px solid var(--maroonColor); 
    box-shadow: 1px 1px 5px var(--yellowColor);
    -webkit-box-shadow: 1px 1px 5px var(--yellowColor);
    -moz-box-shadow: 1px 1px 5px var(--yellowColor);
}
.hrBottomFooter{
    margin-top:1rem;
    padding: 0;
    height: 2px;
    border-top: 1px solid var(--mainLight); 
    /* box-shadow: 1px 1px 5px var(--yellowColor);
    -webkit-box-shadow: 1px 1px 5px var(--yellowColor);
    -moz-box-shadow: 1px 1px 5px var(--yellowColor); */

}

@media (max-width: 778px) {
    .pageFooter{
       padding: .8rem .5rem;
    }
    .hrBottomFooter{
        margin-top:.5rem;
    }
  }

.footerNavHeading{
    font-size: 1rem;
    padding: 0;
} 
.footerNavList{
list-style: none;
margin: 0;
padding: 0;
}
.footerNavItem{
font-size: .8rem;
}
.footerCopyright{
    font-size: .8rem;
    font-style: italic;
    margin-top: .5rem;
}
@media (max-width: 576px) {
    .footerNavList{
        margin-bottom: .5rem;
    }
}
 .footerNavItem a:hover{
    color: var(--yellowColor);
}
